<template>
  <div class="pa-0">
    <nav>
      <v-toolbar id="navbar" style="backgroundColor: #002749" class="white--text">
          <v-toolbar-title>
            <span class="font-weight-light pr-3">Jinglee Classrooms </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="!$vuetify.breakpoint.smAndDown"> 
            <v-btn text v-for="(item, i) in items" :key="i" :to="item.link" class="white--text"> {{item.text}}</v-btn>
          </div>
          <v-app-bar-nav-icon @click="side= !side" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
      </v-toolbar>
    </nav>
    <div>
      <v-card class="py-7">
        <v-row class="pb-8">
          <v-col cols="12"><h1 align="center" justify="center" class="indigo--text darken-4--text">See What's Inside</h1></v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="6" sm="3">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-card-title class="d-flex align-center justify-center">
                <v-icon large>mdi-finance</v-icon>
              </v-card-title>
              <v-card-title class="d-flex align-center justify-center">Finance System</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-card-title class="d-flex align-center justify-center">
                <v-icon large>mdi-av-timer</v-icon>
              </v-card-title>
              <v-card-title class="d-flex align-center justify-center">Admission Management</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
           <v-card class="mx-auto" max-width="344" outlined>
              <v-card-title class="d-flex align-center justify-center">
                <v-icon large>mdi-microsoft-onenote</v-icon>
              </v-card-title>
              <v-card-title class="d-flex align-center justify-center">Attendance Management</v-card-title>
            </v-card>
          </v-col>
          <v-col cols="6" sm="3">
           <v-card class="mx-auto" max-width="344" outlined>
              <v-card-title class="d-flex align-center justify-center">
                <v-icon large>mdi-chart-timeline</v-icon>
              </v-card-title>
              <v-card-title class="d-flex align-center justify-center">Result Management</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row class="px-10">
          <v-col cols="6" sm="3">
            <v-sheet color="green lighten-3" height="195"></v-sheet>
          </v-col>
          <v-col cols="6" sm="3">
           <v-sheet color="teal lighten-3" height="195"></v-sheet>
          </v-col>
          <v-col cols="6" sm="3">
           <v-sheet color="yellow lighten-3"   height="195"></v-sheet>
          </v-col>
          <v-col cols="6" sm="3">
           <v-sheet color="green lighten-3" height="195"></v-sheet>
          </v-col>
        </v-row> -->
      </v-card>
    </div>
    <v-footer dark padless>
    <v-card flat tile class="white--text text-center col-12" style="backgroundColor: #002749">      
     <v-row>
         <v-col cols="12" lg="4" xl="4" md="4" sm="12" >
          <v-card-text class="text-center title">Meet Us At</v-card-text>
          <v-divider></v-divider>
          <v-card-text> Jinglee Pvt. Ltd <br> 6/9, Jolarpettai <br> Tirupattur, India - 620 102.<br>
          </v-card-text>
      </v-col>
      <v-col cols="12" lg="4" xl="4" md="4" sm="12">
          <v-card-title>About Jinglee Classroom</v-card-title>
          <v-card-text class="text-justify">
            Jinglee Classrooms is one of the Fast growing and experienced full service geospatial solutions company in Trichy cosmopolitan city, India. We have a long tradition of consistent performance and enduring client relationships built on over along to quality service. No job is too small or large, we provide our services to independent geomatics companies, government bodies and transport authorities.
          </v-card-text>
      </v-col>
      <v-col cols="12" lg="4" xl="4" md="4" sm="12">
          <v-card-text class="text-center "><v-icon large>mdi-twitter</v-icon></v-card-text>
          <v-card-text class="text-justify">
            Jinglee Classrooms has the experience, Technical resources, and worldwide capacity to enable excellent performance on any geospatial projrcts. <a href="#">www.twitter.com</a> Nov 7, 2014
          </v-card-text>
      </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Jinglee Classrooms.</strong> All Rights Reserved.
      </v-card-text>
    </v-card>
  </v-footer>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          { text: 'Home', icon: 'mdi-home-variant',link:'' },
          { text: 'About Us', icon: 'mdi-cart',link:'' },
          { text: 'Login', icon: 'mdi-account',link:'/login'},
        ]
      }
    }
  }
</script>